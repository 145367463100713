<template>
  <div class="consent-form">
    <div class="content">
      <h1>知情同意书</h1>
      <p>尊敬的参与者：</p>
      <p>感谢您参加我们的成果体验。在您同意参加体验之前，请仔细阅读以下内容。</p>

      成果体验的输入主要由一些个人基本信息和望、闻、问、切四诊信息构成。
      这些信息将由我们研究课题中成熟的算法及模型进行处理和维护。
      在你决定参加这项体验之前，请仔细阅读以下内容。如果你有任何疑问，欢迎随时向我们提问。

      <p><strong>体验目的：</strong><br>们的体验旨在探讨智能四诊在健康和疾病诊断中的应用。通过收集你的四诊信息，我们将分析不同特征与健康状态之间的关联。</p>
      <p><strong>体验过程：</strong><br>你除了被要求填写一些个人基本信息和问诊信息外，还将被要求坐在适当的位置和安静的环境拍摄你的舌面图像和采集你的闻诊声音。这个过程通常很快，不会对你的身体造成伤害。</p>
      <p><strong>风险和不适：</strong><br>舌面诊采集图像和闻诊声音采集是无创的，不会对你的身体造成伤害。有可能会感到一些不适，例如张口或保持舌头在特定位置。</p>
      <p><strong>益处：</strong><br>你的参与将有助于推动医学研究，改善健康诊疗及预后管理。你将有机会了解自己的智能医学诊断信息。</p>
      <p><strong>隐私保护：</strong><br>我们将严格保护你的隐私，只有研究团队才能访问你的数据。你的个人信息将被匿名化处理。你的参与是完全自愿的。如果你决定退出研究，你可以随时停止参与，而不会受到任何不良后果。</p>
      <p><strong>自愿参与：</strong><br>你的参与是完全自愿的。如果你决定退出研究，你可以随时停止参与，而不会受到任何不良后果。</p>
      <p>请确保你已经理解了上述内容。如果你有其他问题或需要更多信息，请随时告知。感谢你的参与!</p>
      <p>通过点击下方的"我同意"按钮，表示你已经理解并同意参加这项体验。</p>

      <button   @click="submitConsent">我同意</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConsentForm',
  data() {
    return {
      isAgreed: false
    };
  },
  methods: {
    submitConsent() {
      console.log(this.isAgreed);
      this.isAgreed = true;
      // 这里可以添加提交参与者同意状态的逻辑
      this.$emit('submit-consent',this.isAgreed);
      // 这里可以添加导航到下一个页面或其他操作的逻辑
    }
  }
};
</script>

<style scoped>
.consent-form {
  max-width: 600px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.content h1 {
  text-align: center;
}

button {
  margin: 20px 0;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
}

button.agreed {
  background-color: #4CAF50;
  color: white;
  border: none;
}

button.agreed:disabled {
  background-color: #ccc;
}
</style>
