<template>
  <div class="home3">
    <div class="container-pluse flex-column">
      <h2 class = 'mai-title'>脉诊</h2>
      <div style="display:flex;justify-content: center; align-items:center;">
          <div class="captured-photos">
            <div v-if="fileName">
              已上传文件：{{ fileName }}
            </div>
          </div>
          <div class="button-container">
              <div v-if="user_upload_pulse" class="uploaded-photo-container">
                <el-button v-show="user_upload_pulse" @click="removeUserUploadPulse" type="danger">删除</el-button>
              </div>
              <div class="custom-file-upload" v-if="!user_upload_pulse">
                自己上传文件
                <input  placeholder="点击上传文件" type="file" @change="userUploadPulse"  accept=".npy">
              </div>
            <div class="uploaded-photo-container">
              <el-button type="success"  @click="submitPulse" v-if="user_upload_pulse">保存</el-button>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import  { data } from './test.js'

export default {
  data() {
    return {
      // 初始化所需的数据...
      // user_upload_pulse: null,
      user_upload_pulse: data.pluse,
      filtered_results: [],
      fileName: '平脉',
    };
  },

  methods: {

    userUploadPulse(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.user_upload_pulse = e.target.result;
      };
      reader.readAsDataURL(file);
      event.target.value = '';
    },

    removeUserUploadPulse() {
      this.user_upload_pulse = null;
      this.filtered_results = []
    },

    submitPulse() {
      this.$emit('submit-pulse-view', this.user_upload_pulse);

      this.$notify({
        title: '成功',
        message: '脉诊内容已保存，请点击提交进行诊断',
        type: 'success'
      });
    },
  }
}
</script>

<style>
.home3 {
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  align-items: center;
  padding: 20px;
  gap: 20px; /* 添加组件之间的间距 */
}

.captured-photos {
  display: flex;
  flex-direction: column;
//flex-direction: row; /* 水平排列上传的照片 */
  align-items: center; /* 垂直居中对齐 */
  margin-top: 20px;
}
.button-container{

}

button {
  padding: 8px 15px;
  font-size: 14px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
input[type="file"] {
//opacity: 0;    /* 使文件输入框完全透明，但仍然可以交互 */
  width: 140px;
  height: 60px; /* 设置一个足够的高度 */
  position: absolute; /* 定位到自定义上传按钮的位置 */
  cursor: pointer; /* 改变鼠标指针样式，表示可点击 */
}
.results{
  border: 1px solid #ccc;
  padding: 10px;

}

.mai-title{
  margin-top: 100px;
}
</style>
