var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consent-form"},[_c('div',{staticClass:"content"},[_c('h1',[_vm._v("知情同意书")]),_c('p',[_vm._v("尊敬的参与者：")]),_c('p',[_vm._v("感谢您参加我们的成果体验。在您同意参加体验之前，请仔细阅读以下内容。")]),_vm._v(" 成果体验的输入主要由一些个人基本信息和望、闻、问、切四诊信息构成。 这些信息将由我们研究课题中成熟的算法及模型进行处理和维护。 在你决定参加这项体验之前，请仔细阅读以下内容。如果你有任何疑问，欢迎随时向我们提问。 "),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('p',[_vm._v("请确保你已经理解了上述内容。如果你有其他问题或需要更多信息，请随时告知。感谢你的参与!")]),_c('p',[_vm._v("通过点击下方的\"我同意\"按钮，表示你已经理解并同意参加这项体验。")]),_c('button',{on:{"click":_vm.submitConsent}},[_vm._v("我同意")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("体验目的：")]),_c('br'),_vm._v("们的体验旨在探讨智能四诊在健康和疾病诊断中的应用。通过收集你的四诊信息，我们将分析不同特征与健康状态之间的关联。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("体验过程：")]),_c('br'),_vm._v("你除了被要求填写一些个人基本信息和问诊信息外，还将被要求坐在适当的位置和安静的环境拍摄你的舌面图像和采集你的闻诊声音。这个过程通常很快，不会对你的身体造成伤害。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("风险和不适：")]),_c('br'),_vm._v("舌面诊采集图像和闻诊声音采集是无创的，不会对你的身体造成伤害。有可能会感到一些不适，例如张口或保持舌头在特定位置。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("益处：")]),_c('br'),_vm._v("你的参与将有助于推动医学研究，改善健康诊疗及预后管理。你将有机会了解自己的智能医学诊断信息。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("隐私保护：")]),_c('br'),_vm._v("我们将严格保护你的隐私，只有研究团队才能访问你的数据。你的个人信息将被匿名化处理。你的参与是完全自愿的。如果你决定退出研究，你可以随时停止参与，而不会受到任何不良后果。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("自愿参与：")]),_c('br'),_vm._v("你的参与是完全自愿的。如果你决定退出研究，你可以随时停止参与，而不会受到任何不良后果。")])
}]

export { render, staticRenderFns }