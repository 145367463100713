<template>
  <div class="container" >
    <h2>基本信息</h2>
    <div class="information-container">
      <div class="row">
        <input type="text"   placeholder="请输入病历号(若无则输入0)" v-model="user.medicalRecordNumber" class = 'per-inf-box'>
        <input type="text" @blur="checkPhoneFormat" placeholder="请输入您的电话" v-model="user.phone" class = 'per-inf-box'>
        <input type="text" placeholder="请输入您的身高/厘米(整数)" v-model="user.height" class = 'per-inf-box'>
        <input type="text" placeholder="请输入您的体重/公斤(整数)" v-model="user.weight" class = 'per-inf-box'>
        <input type="text" placeholder="请输入您的年龄/岁" v-model="user.age" class = 'per-inf-box'>

        <select v-model="user.gender" >
          <option value="" selected disabled >请选择性别</option>
          <option value="男">男</option>
          <option value="女">女</option>
        </select>
      </div>
    </div>
    <div>
      <div>
        <div class = 'lv-two'>最近症状:</div>
        <input type="text" placeholder="请输入最近症状" class="infor-box" v-model="user.recentSymptoms">
      </div>

        <div class = 'lv-two'>既往病史:</div>
        <div v-for="(group, i) in pastMedicalHistory" :key="group.title" class="form-group">
          <label v-for="(item, j) in group.options" :key="j" class="checkbox-container">
            <input
                type="checkbox"
                :value="item"
                v-model="group.values"
                :id="item"
                :name="group.title"
                :checked="Array.isArray(group.values) && group.values.includes(item)"
            />
            <span :for="item">{{ item }}</span>
            <span class="checkmark"></span>
          </label>
        </div>

    </div>
    <h2 class = 'look-title'>问诊</h2>
    <div class="container-questions">
      <div class="row">
        <div class="question-container" v-for="(question, index) in questions" :key="index">
          <div class="question-title">{{ index + 1 }}. {{ question.title }}</div>
          <div class="options-container">
            <label
                v-for="(option, optionIndex) in question.options"
                :key="optionIndex"
                class="option"
            >
              <input
                  type="radio"
                  :value="optionIndex"
                  v-model="results[index]"
                  :checked="results[index] === optionIndex"
              @change="saveResults(index, optionIndex)"
               />
              {{ question.options[optionIndex] }}
            </label>
          </div>
        </div>
      </div>
    </div>
<!--    <button v-if="!allQuestionsAnswered" class="save-button" @click="submit">保存</button>-->
    <el-button v-if="!allQuestionsAnswered" type="success"  @click="submit" >保存</el-button>

    <el-button v-if="allQuestionsAnswered" @click="clear" type="danger">清空重填</el-button>
    <div class="results" v-if="physique[0]">诊断结果
      <p v-for = "result in physique" :key="result" >{{ result }}</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      questions: [
        {
          title: '您精力充沛吗? (指精神头足，乐于做事)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: '' //原本是''
        },
        {
          title: '您容易疲乏吗? (指体力如何，是否稍微活动一下或做一点家务劳动就感到累)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: 3
        },
        {
          title: '您容易气短，呼吸短促，接不上气吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您说话声音低弱无力吗?(指说话没有力气)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您感到闷闷不乐、情绪低沉吗?(指心情不愉快，情绪低落)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您容易精神紧张、焦虑不安吗?(指遇事是否心情紧张)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您因为生活状态改变而感到孤独、失落吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您容易感到害怕或受到惊吓吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您感到身体超重不轻松吗?(感觉身体沉重)[BMI指数=体重 (kg) /身高2 (m)]',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您眼睛干涩吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您手脚发凉吗?(不包含因周围温度低或穿的少导致的）',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您胃部、背部或腰膝部怕冷吗? (指上腹部、背部、腰部或膝关节等，有一处或多处怕冷)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您比一般人耐受不了寒冷吗? (指比别人容易害怕冬天或是夏天的冷空调、电扇等)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您容易患感冒吗? (指每年感冒的次数)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您没有感冒时也会息寒、流息涕吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您有口粘口腻，或睡眠打鼾吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您容易过敏(对药物、食物、气味、花粉或在季节交替、气候变化时)吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您的皮肤容易起尊麻疹吗? (包括风团、风疹块、风挖瘩)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您的皮肤在不知不觉中会出现青紫瘀斑、皮下出血吗?(指皮肤在没有外伤的情况下出现青一块紫一块的情况）',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您的皮肤一抓就红，并出现抓痕吗?(指被指甲或钝物划过后皮肤的反应)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您皮肤或口唇干吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您有肢体麻木或固定部位疼痛的感觉吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您面部或鼻部有油腻感或者油亮发光吗?(指脸上或鼻子)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您面色或目眶晦躇，或出现祸色斑块/斑点吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您有皮肤湿疹、疮疗吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您感到口干舌燥、总想喝水吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您感到口苦或嘴里有异味吗? (指口苦或口臭)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您腹部肥大吗?(指腹部脂肪肥厚)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您吃(喝)凉的东西会感到不舒服或者怕吃(喝)凉的东西吗? (指不喜欢吃凉的食物，或吃了凉的食物后会不舒服)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您有大便黏滞不爽、解不尽的感觉吗?(大便容易粘在马桶或便坑壁上)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您容易大便干燥吗?',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您舌苔厚腻或有舌苔厚厚的感觉吗?(如果自我感觉不清楚可由调查员观察后填写)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
        {
          title: '您舌下静脉瘀紫或增粗吗? (可由调查员辅助观察后填写)',
          options: ['没有', '很少', '有时', '经常', '总是'],
          answer: ''
        },
      ],
      // results: [1,2,3,2,3,2,1,3,2,2,2,3,2,1,2,3,2,3,1,2,3,2,1,3,1,2,2,3,2,2,3,2,1],
      results: [],
      grade: [],
      physique: [],
      user: {height:'',
            weight:'',
            age:'',
            gender:'',
            phone:'',
            medicalRecordNumber:'',
            recentSymptoms:'',
            },
      pastMedicalHistory: [
        {
          options: ['心脏疾病', '肝脏疾病', '脾胃疾病', '呼吸系统疾病', '肾脏疾病', '高血压疾病', '糖尿病', '长期服用药物', '重大手术'],
          values: []
        },],
      allQuestionsAnswered: false,
    };
  },


  methods: {
    saveResults(index, questionIndex) {
      this.questions[index].answer = questionIndex + 1;
    },

    checkIfAllFinished(){
      for (let i = 0; i < this.questions.length; i++) {
        if (this.results[i] === undefined || this.results[i] === null) {
          this.allQuestionsAnswered= false
          const questionNumber = i + 1; // 因为题目编号通常从1开始
          const message = `第${questionNumber}题未完成，请检查`;
          this.$notify({
            title: '警告',
            message: message,
            type: 'warning',
          });
          break
        }else{
          this.allQuestionsAnswered= true
        }
      }
    },
    checkInfomation(){
      if (this.user.age && this.user.gender && this.user.height && this.user.weight && this.user.phone && this.user.medicalRecordNumber) {
          return true
      } else {
        this.$notify({
          title: '警告',
          message: '有个人信息未填写，请回到问诊页面顶端检查',
          type: 'warning'
        });
        return false
      }
    },
    submit() {

      if (this.checkInfomation()){
        this.checkIfAllFinished()
      }
      // 检查是否所有题目都已填写完毕
      if (this.allQuestionsAnswered=== true && this.checkInfomation()) {
        // 所有题目都已填写完毕，执行保存操作
        let result = this.questions.map((question, index) => {
          return {
            title: question.title,
            answer: question.answer,
          };
        });

        // let result = this.results;
        this.grade[0]=result[1].answer+result[2].answer+result[3].answer+result[13].answer;
        this.grade[1]=result[10].answer+result[11].answer+result[12].answer+result[28].answer;
        this.grade[2]=result[9].answer+result[20].answer+result[25].answer+result[30].answer;
        this.grade[3]=result[8].answer+result[15].answer+result[27].answer+result[31].answer;
        this.grade[4]=result[22].answer+result[24].answer+result[26].answer+result[29].answer;
        this.grade[5]=result[18].answer+result[21].answer+result[23].answer+result[32].answer;
        this.grade[6]=result[4].answer+result[5].answer+result[6].answer+result[7].answer;
        this.grade[7]=result[14].answer+result[16].answer+result[17].answer+result[19].answer;
        this.grade[8]=result[0].answer+this.reverse(result[1].answer)+this.reverse(result[3].answer)+this.reverse(result[4].answer)+this.reverse(result[12].answer);


        this.$emit('submit-wenzhen-view',   this.grade, this.user,this.pastMedicalHistory[0].values,this.results);

        this.$notify({
          title: '成功',
          message: '问诊内容已保存，请完成面诊',
          type: 'success'
        });

        this.grade=[]// 清空数据
        this.allQuestionsAnswered=true
      }else {

      }

    },
    reverse(i){
      if(i===1) return 5
      if(i===2) return 4
      if(i===3) return 3;
      if(i===4) return 2;
      if(i===5) return 1;
      return i
    },
    checkPhoneFormat() {
      const phoneRegex = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!phoneRegex.test(this.user.phone)) {
        this.$notify.error({
          title: '错误',
          message: '手机号格式不正确'
        });
      }
    },
    clear() {
      this.results = [];
      this.allQuestionsAnswered=false
    }
  }
};
</script>

<style>
.container {
  background-color: #ffffff;
  /* 容器内边距 */
  //display: flex;
  //flex-direction: column;
  //align-items: flex-start;
  padding: 20px 20px 100px;
  max-height: 1000px;
  overflow-y: scroll;
}
.information-container{
  //width: 50%; /* 问题宽度 */
  margin-bottom: 20px; /* 问题间距 */
  background-color: #f9f9f9; /* 背景色 */
  border-radius: 8px; /* 边角圆滑 */
  padding: 15px; /* 问题内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 盒阴影效果 */
}
.container-questions{
  vertical-align:middle;
}
.question-container {
  //width: 50%; /* 问题宽度 */
  margin-bottom: 20px; /* 问题间距 */
  background-color: #f9f9f9; /* 背景色 */
  border-radius: 8px; /* 边角圆滑 */
  padding: 15px; /* 问题内边距 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 盒阴影效果 */
  display: flex;
  flex-direction: column;
  align-items: center;

}

.question-title {
  font-weight: bold; /* 标题加粗 */
  margin-bottom: 10px; /* 标题与选项间距 */
  color: #333; /* 标题颜色 */
}

.options-container {
  display: flex; /* 选项布局 */
  //flex-direction: column; /* 选项竖直排列 */
  flex-direction: row;
  align-items: center;

}

.option {
  margin: 5px 0; /* 选项间距 */
  padding: 8px; /* 选项内边距 */
  border-radius: 5px; /* 选项边角圆滑 */
  transition: background-color 0.3s; /* 背景色过渡动画 */
}

.option:hover {
  background-color: #e6e6e6; /* 鼠标悬停时选项背景色 */
}

.save-button-container {
  display: flex; /* 按钮容器布局 */
  justify-content: center; /* 按钮居中对齐 */
  margin-top: 20px; /* 与问题间距 */
}

.save-button {
  padding: 10px 30px; /* 按钮内边距 */
  color: white; /* 按钮文字颜色 */
  background-color: #1a73e8; /* 按钮背景色 */
  border: none; /* 无边框 */
  border-radius: 4px; /* 按钮边角圆滑 */
  font-size: 16px; /* 文字大小 */
  cursor: pointer; /* 鼠标样式 */
}

.save-button:hover {
  background-color: #1669c7; /* 鼠标悬停时按钮背景色 */
}
.results{
  border: 1px solid #ccc;
  padding: 10px;

}
.infor-box {
  width: 70vw; /* 设置宽度为100% */
  height: 180px; /* 设置默认高度 */
  box-sizing: border-box; /* 确保padding和border不会增加元素的宽度 */
  padding: 10px; /* 设置适当的内边距 */
  font-size: 16px; /* 设置字体大小 */
}

.infor-box::placeholder {
  color: #2c3e50; /* 设置占位符的颜色为红色 */
}

.per-inf-box::placeholder {
  color: #2c3e50; /* 设置占位符的颜色为红色 */
}

.lv-two{
  font-weight: bold; /* 标题加粗 */
  margin-bottom: 10px; /* 标题与选项间距 */
  color: #333; /* 标题颜色 */

}

.look-title{
  margin-top: 100px;
}
</style>
