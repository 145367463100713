<template>
  <div class="recorder">
    <div>
      <h2 class = "ask-title">闻诊</h2>
      <p>请阅读以下元音字母:A、E、I、O、U</p>
    </div>
    <div v-if="!user_upload_audio">
      <button @click="startRecording" v-if="!recording">开始录音</button>
      <button @click="stopRecording" v-else>停止录音</button>
    </div>
    <div v-if="user_upload_audio">
      <audio controls :src="user_upload_audio"></audio>
      <div>
        <el-button type="success" @click="submitVoice" >保存</el-button>
        <el-button type="danger" @click="deleteVoice">删除</el-button>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      mediaRecorder: null,
      chunks: [],
      recording: false,
      audioBlob: null, // 存储Blob对象
      user_upload_audio: null
    };
  },
  methods: {
    startRecording() {
      navigator.mediaDevices.getUserMedia({ audio: true })
          .then(stream => {
            this.mediaRecorder = new MediaRecorder(stream);
            this.mediaRecorder.start();
            this.recording = true;

            this.mediaRecorder.ondataavailable = event => {
              this.chunks.push(event.data);
            };

            this.mediaRecorder.onstop = () => {
              this.processChunks();
            };
          })
          .catch(err => console.error("Error accessing media devices.", err));
    },
    stopRecording() {
      this.mediaRecorder.stop();
      this.recording = false;
    },
    processChunks() {
      this.audioBlob = new Blob(this.chunks, { type: "audio/wav" });
      this.user_upload_audio = URL.createObjectURL(this.audioBlob);
      this.chunks = [];  // Reset chunks for the next recording
    },
    deleteVoice(){
      this.user_upload_audio = null
    },
    submitVoice(){
        this.$emit('submit-voice-view', this.audioBlob);
        this.$notify({
          title: '成功',
          message: '闻诊内容已保存，请完成脉诊',
          type: 'success'
        });
        return true;
      },
    savaTest(){
          const formData = new FormData();
          // formData.append('voiceFile', this.audioBlob);
          formData.append('voiceFile', this.audioBlob, 'recording.wav'); // 指定文件名
          fetch('http://localhost:9001/Ai/Save/Image/voice', {
            method: 'POST',
            body: formData,
          })
              .then(response => response.json())
              .then(data => {
                console.log('Upload successful:', data);
              })
              .catch(error => {
                console.error('Upload failed:', error);
              });
    }
  }
};
</script>

<style>
/* Add your styles here */
.recorder{
  background-color: #f5f5f5;
  padding: 20px;
  height: 220px;

}
.ask-title{
  margin-top: 90px;
}
</style>
