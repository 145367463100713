import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tongueViewFilteredResults: []
  },
  getters: {
    getTongueViewFilteredResults: state => state.tongueViewFilteredResults
  },
  mutations: {
    SET_TONGUE_VIEW_FILTERED_RESULTS(state, data) {
      // 将数据存储在App.vue组件内的数据属性中
      // 这通常通过Vuex的getter在组件内访问
      state.tongueViewFilteredResults = data;
    }
  },
  actions: {
    updateTongueViewFilteredResults({ commit }, data) {
      commit('SET_TONGUE_VIEW_FILTERED_RESULTS', data);
    }
  },
  modules: {
  }
})
