<template>
  <div id="app">
    <!--    <router-view/>-->
    <!-- 使用v-if来控制知情同意书的显示 -->
    <agreement  v-if="showConsentForm" @submit-consent="handleConsentSubmit" ></agreement>
    <!-- 其他视图在同意之后显示，使用v-show可以简化操作 -->
    <div  v-show="!showConsentForm">
      <WenzhenView @submit-wenzhen-view="handleWenzhenViewSubmit"></WenzhenView>
      <FaceView @submit-face-view="handleFaceViewSubmit"></FaceView>
      <TongueView @submit-tongue-view="handleTongueViewSubmit" ></TongueView>
      <VoiceView @submit-voice-view="handleWen2zhenViewSubmit"></VoiceView>
      <PulseView @submit-pulse-view="handlePulseViewSubmit"></PulseView>
      <button @click="submitToDiagnose">提交进行诊断</button>
    </div>

    <!--    <loading-overlay :active.sync="isLoading"-->
    <!--                     :can-cancel="true"-->
    <!--                     :is-full-page="fullPage"></loading-overlay>-->
    <div v-if="isLoading" class="overlay">
      <div class="spinner-wrapper">
        <vue-simple-spinner
            :size="60"
            :line-size="5"
            :line-bg="'#3498db'"
            :line-fg="'#ffffff'"
            :speed="1.5"
        ></vue-simple-spinner>
        <p class="loading-text">请等候...</p>
      </div>
    </div>
  </div>
</template>

<script>
import VoiceView from "@/views/VoiceView.vue";
import FaceView from "@/views/FaceView.vue";
import TongueView from "@/views/TongueView.vue";
import WenzhenView from "@/views/WenzhenView.vue";
import PulseView from "@/views/PulseView.vue";
import agreement from "@/views/aggreement.vue";
import LoadingOverlay from 'vue-loading-overlay';
import VueSimpleSpinner from 'vue-simple-spinner';
import 'vue-loading-overlay/dist/vue-loading.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';



let BaseUrl = "https://endpointitcmserver.imedlabwyu.com:";

export default {

  name: 'App',
  components: {
    agreement,
    VoiceView,
    FaceView,
    TongueView,
    WenzhenView,
    PulseView,
    LoadingOverlay,
    VueSimpleSpinner
  },
  data() {
    return {
      showConsentForm: true,
      user: {height:'',
        weight:'',
        age:'',
        gender:'',
        phone:'',
        medicalRecordNumber:'',
        recentSymptoms:'',
      },
      pastMedicalHistory:[],
      wenzhenViewpGrade:[],
      wenzhenViewpGradeAnswer:[],
      faceViewPhoto:null,
      tongueViewPhoto:null,
      voiceViewAudio:null,
      pulseViewPulse:null,
      physique:{
        wenzhenPhysique:[],
        facePhysique:[],
        tonguePhysique:[],
        pulsePhysique:[],
      },
      isLoading: false,
      fullPage: true,
      //测试AI模型
      requestBody: {
        model: 'LLM_in_vllm',
        messages: [
          {
            role: 'system',
            content: '你的身份是一位经验丰富的老中医，精通望、闻、问、切四诊，能够根据患者的具体症状，准确分析患者可能的病因，并能提供相关的医学解释和可靠的医学资料支持。',
          },
          {
            role: 'user',
            content:'从中医的角度来看下巴：黄，右脸颊：黄，左脸颊：黄，额头：黄，鼻子：黄，问诊结果是：气郁质，湿热质。舌诊得到的体质为：痰湿质，血瘀质，脉诊得到的脉象为：滑脉。等症状可能是什么原因\n 补充资料：年龄：24，性别：男，身高：175，体重：70，既往病史：无'
          },
        ],
      },

    }
  },
  computed: {
    // 你可以创建计算属性来简化访问嵌套对象的属性
    chinColor() {
      return this.physique.facePhysique.filtered_results.下巴;
    },
    rightCheekColor() {
      return this.physique.facePhysique.filtered_results.右脸颊;
    },
    leftCheekColor() {
      return this.physique.facePhysique.filtered_results.左脸颊;
    },
    headColor() {
      return this.physique.facePhysique.filtered_results.额头;
    },
    noseColor() {
      return this.physique.facePhysique.filtered_results.鼻子;
    },
    tonguePhysiqueDescription() {
      // 假设你想要一个包含所有舌诊体质的字符串
      return this.physique.tonguePhysique[0].join(", ");
    },
    pulsePhysique() {
      return this.physique.pulsePhysique[0];
    },
    wenzhenPhysiqueDescription() {
      // 同上，创建一个包含所有问诊体质的字符串
      return this.physique.wenzhenPhysique.join(", ");
    },
  },
  methods: {


    // origin
    handleConsentSubmit(isAgree) {
      this.showConsentForm = !isAgree;

      Swal.fire({
        title: '操作提示',
        html: `<div class="justified-text">
            请您依次按照基本信息-问诊-面诊-舌诊-脉诊的顺序完成体验。请您拍照时不要化妆，保持皮肤的自然状态。尽量选择纯色背景和自然的光照条件。录音时尽量处在安静的环境当中。（请注意，目前脉诊模块由于用户无法获取到NPY文件，所以默认上传了“平脉”脉象，只需要点击“保存”即可）。该系统处在开发和测试阶段，所有结果只供参考不作医疗指导。系统的解释权归五邑大学智能医学实验室所有。有任何疑问，请联系：j002443@wyu.edu.cn
            </div>`,
        imageUrl: 'https://itcm-wyu.oss-cn-guangzhou.aliyuncs.com/example/logo/imedLOGO.png',
        imageWidth: 200,
        imageHeight: 200,
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
    ,
    handleWenzhenViewSubmit(grade, user, pastMedicalHistory, results) {
      this.wenzhenViewpGrade = grade;
      this.user = user;
      this.pastMedicalHistory = pastMedicalHistory
      this.wenzhenViewpGradeAnswer = results
    },
    handleFaceViewSubmit(user_upload_photo) {
      this.faceViewPhoto = user_upload_photo;
    },
    handleTongueViewSubmit(user_upload_photo) {
      this.tongueViewPhoto = user_upload_photo;
    },
    handleWen2zhenViewSubmit(user_upload_audio) {
      this.voiceViewAudio = user_upload_audio
    },
    handlePulseViewSubmit(user_upload_pulse) {
      this.pulseViewPulse = user_upload_pulse;
    },

    async submitToDiagnose() {
      // 在执行任何异步操作之前进行检查
      if (this.wenzhenViewpGradeAnswer.length === 0) {
        await Swal.fire({
          title: '警告',
          text: '问诊答案未保存，请点击保存按钮后再提交。',
          icon: 'warning',
          confirmButtonText: '确定'
        });
        // 如果脸部照片为空，不执行后续的异步代码
        return;
      }
      if (!this.faceViewPhoto) {
        await Swal.fire({
          title: '警告',
          text: '脸部照片未保存，请点击保存按钮后再提交。',
          icon: 'warning',
          confirmButtonText: '确定'
        });
        // 如果脸部照片为空，不执行后续的异步代码
        return;
      }
      if (!this.tongueViewPhoto) {
        await Swal.fire({
          title: '警告',
          text: '舌部照片未保存，请点击保存按钮后再提交。',
          icon: 'warning',
          confirmButtonText: '确定'
        });
        // 如果脸部照片为空，不执行后续的异步代码
        return;
      }
      if (!this.voiceViewAudio) {
        await Swal.fire({
          title: '警告',
          text: '声音文件未保存，请点击保存按钮后再提交。',
          icon: 'warning',
          confirmButtonText: '确定'
        });
        // 如果脸部照片为空，不执行后续的异步代码
        return;
      }
      if (!this.pulseViewPulse) {
        await Swal.fire({
          title: '警告',
          text: '脉象文件未保存，请点击保存按钮后再提交。',
          icon: 'warning',
          confirmButtonText: '确定'
        });
        // 如果脸部照片为空，不执行后续的异步代码
        return;
      }

      this.isLoading = true;
      try {
        // 使用 Promise.all 并行执行所有请求，并确保每个请求都返回 Promise
        await Promise.all([
          this.wenzhenSubmit().then(response => {
            // 假设 wenzhenSubmit 成功后，saveWenzhen 不需要传入任何参数
            return this.saveWenzhen(); // 这里返回 saveWenzhen 的 Promise
          }),
          this.faceSubmit().then(response => {
            // 同样，返回 saveFacePhoto 的 Promise
            return this.saveFacePhoto();
          }),
          this.tongueSubmit().then(response => {
            // 返回 saveTonguePhoto 的 Promise
            return this.saveTonguePhoto();
          }),
          this.saveVoice().then(() => {
            // 返回 saveVoice 的 Promise
            return Promise.resolve();
          }),
          // 如果 pulseSubmit 不需要保存，你可以直接返回 pulseSubmit 的 Promise
          this.pulseSubmit().then(() => {
            // 如果这里不需要做任何操作，可以返回一个解析的 Promise
            return Promise.resolve();
          }),
        ]);
        // 所有请求都已完成
        // 这里可以添加提交成功的逻辑
        //在这里拼接一下去陈老师模型的数据
        const chinColor = this.chinColor;
        const rightCheekColor = this.rightCheekColor;
        const leftCheekColor = this.leftCheekColor;
        const headColor = this.headColor;
        const noseColor = this.noseColor;
        const tonguePhysique = this.tonguePhysiqueDescription;
        const wenzhenPhysique = this.wenzhenPhysiqueDescription;
        const pulsePhysique = this.pulsePhysique;
        const user = this.user
        const pastMedicalHistory = this.pastMedicalHistory.join(", ")
        console.log(wenzhenPhysique)
        // this.requestBody.messages[1].content = '从中医的角度来看下巴：'+下巴+'右脸颊：'+右脸颊+'左脸颊：'+左脸颊++'额头：'+额头+'鼻子：'+鼻子+'问诊结果是：'+ wenzhenPhysique[0]+'，'+wenzhenPhysique[1]+'，'+wenzhenPhysique[2]+'。'+ '等症状可能是什么原因\\n' + '补充材料：年龄：23，性别：女，既往病史：无，家族病史：无，过敏史：无，吸烟史：无，饮酒史：无，体检：正常，家族史：无，职业：医生。';
        const content = `从中医的角度来看,下巴呈${chinColor}色，右脸颊呈${rightCheekColor}色，左脸颊呈${leftCheekColor}色，额头呈${headColor}色，鼻子呈${noseColor}色。问诊结果得到体质属于${wenzhenPhysique}。舌诊得到的体质为${tonguePhysique}，脉诊得到的脉象为：${pulsePhysique}。通过上述信息，希望你能从中医的面诊，舌诊，问诊以及脉诊四个点对我的身体健康情况进行分析，希望你的回答是一个固定的格式，比如1、面诊：。2、舌诊：。3、：问诊：。4、脉枕：。综合分析。并告诉我的体质是属于九种体质的哪一种\\n 补充资料：年龄：${user.age}，性别：${user.gender}，身高：${user.height}，体重：${user.weight}，既往病史：${pastMedicalHistory || "无"},最近症状:${user.recentSymptoms|| "无"}`
        console.log(content)
        this.requestBody.messages[1].content = content

        await this.goToChen();
      } catch (error) {
        // 捕获并处理错误
        console.error("提交过程中发生错误：", error);
        // 这里可以添加错误处理逻辑
      } finally {
        // 无论成功还是失败，都关闭 isLoading
        this.isLoading = false;
      }
    },
    wenzhenSubmit() {
      return fetch('https://endpointitcmserver.imedlabwyu.com:9000/AI4/webUpload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.wenzhenViewpGrade),
      }).then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      }).then((data) => {
        // 处理返回的数据
        this.physique.wenzhenPhysique = data.physique;
        // 在这里进行成功后的操作，比如清空照片变量或提示用户
        this.grade = [];
      }).catch((error) => {
        console.error('Error:', error);
        // 在这里进行失败后的操作，比如提示用户
        throw error; // 确保错误被抛出，以便调用者可以处理
      });
    },
    faceSubmit() {
      // 创建一个新的 Promise，以便我们可以控制 resolve 和 reject
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        // 添加拍好的照片（如果它们是base64编码的字符串）
        formData.append('photo', this.faceViewPhoto);

        // 使用XMLHttpRequest或Fetch API发送请求
        fetch(BaseUrl+'9000'+'/AI2/webUpload', {
          method: 'POST',
          body: formData,
        })
            .then(response => {
              if (!response.ok) {
                Swal.fire({
                  title: '出问题了',
                  text: '请重新拍脸部照片',
                  showCancelButton: true,
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                });
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // 处理返回的数据
              console.log(data);
              this.physique.facePhysique = data;
              // 如果一切顺利，使用 resolve 来标记 Promise 已完成
              resolve();
            })
            .catch(error => {
              // 处理错误
              console.error('Error:', error);
              Swal.fire({
                title: '出问题了',
                text: '请重新拍脸部照片',
                showCancelButton: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              });
              // 使用 reject 来标记 Promise 已被拒绝
              reject(error);
            });
      });
    },
    tongueSubmit() {
      return new Promise((resolve, reject) => {
        // 第一个请求：发送舌象照片进行诊断
        const formData = new FormData();
        formData.append('photo', this.tongueViewPhoto);

        fetch(BaseUrl+'9000'+'/AI1/webUpload', {
          method: 'POST',
          body: formData,
        })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // 处理返回的数据
              this.physique.tonguePhysique = data.filtered_results;
              // 触发保存图片的请求
              resolve();
            })
            .then(() => {
              // 所有请求成功，解决 Promise
              resolve();
            })
            .catch(error => {
              // 捕获错误，拒绝 Promise
              reject(error);
            });
      });
    },
    pulseSubmit() {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        // 添加拍好的照片（如果它们是base64编码的字符串）
        formData.append('pulse', this.pulseViewPulse);

        // 使用XMLHttpRequest或Fetch API发送请求
        fetch(BaseUrl+'9000'+'/AI3/webUpload', {
          method: 'POST',
          body: formData,
        })
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              // 处理返回的数据
              this.physique.pulsePhysique = data.filtered_results;
              // 如果请求成功，使用 resolve 来标记 Promise 已完成
              resolve();
            })
            .catch(error => {
              // 处理错误
              console.error('Error:', error);
              // 使用 reject 来标记 Promise 已被拒绝
              reject(error);
            });
      });
    },
// 保存舌象照片的请求可以作为一个单独的方法
    saveTonguePhoto() {
      const userInfo = JSON.stringify(this.user);
      // 使用 FormData 对象来构建一个表单数据对象
      const formData = new FormData();
      formData.append('photo', this.tongueViewPhoto);
      formData.append('user', userInfo);
      formData.append('pastMedicalHistory', this.pastMedicalHistory);

      return fetch(BaseUrl+'9001'+'/Ai/Save/Image/tongue', {
        method: 'POST',
        body: formData,

      }).then((response) => response.json())
          .then((data) => {
            console.log(data)
          })
          .catch((error) => {
            // 处理错误
            console.error('Error:', error);
          });
    },//已完成
    saveFacePhoto() {
      const userInfo = JSON.stringify(this.user);
      // 使用 FormData 对象来构建一个表单数据对象
      const formData = new FormData();
      formData.append('photo', this.faceViewPhoto);
      formData.append('user', userInfo);
      formData.append('pastMedicalHistory', this.pastMedicalHistory);


      return fetch(BaseUrl+'9001'+'/Ai/Save/Image/face', {
        method: 'POST',
        body: formData,

      }).then((response) => response.json())
          .then((data) => {
            console.log(data)
          })
          .catch((error) => {
            // 处理错误
            console.error('Error:', error);
          });
    },//已完成
    saveWenzhen() {
      const userInfo = JSON.stringify(this.user);
      for (let i = 0; i < this.wenzhenViewpGradeAnswer.length; i++) {
        this.wenzhenViewpGradeAnswer[i] += 1
      }
      // 使用 FormData 对象来构建一个表单数据对象
      const formData = new FormData();
      formData.append('answer', this.wenzhenViewpGradeAnswer);
      formData.append('result', this.physique.wenzhenPhysique);
      formData.append('user', userInfo);
      formData.append('pastMedicalHistory', this.pastMedicalHistory);


      return fetch(BaseUrl+'9001'+'/wenzhen/AiSave', {
        method: 'POST',
        body: formData,

      }).then((response) => response.json())
          .then((data) => {
            console.log(data)
          })
          .catch((error) => {
            // 处理错误
            console.error('Error:', error);
          });
    },
    saveVoice() {
      return new Promise((resolve, reject) => {
        const userInfo = JSON.stringify(this.user);
        const formData = new FormData();
        formData.append('voiceFile', this.voiceViewAudio, 'recording.wav'); // 指定文件名
        formData.append('user', userInfo);
        formData.append('pastMedicalHistory', this.pastMedicalHistory);

        fetch(BaseUrl + '9001/Ai/Save/Image/voice', {
          method: 'POST',
          body: formData,
        })
            .then(response => {
              if (!response.ok) {
                // 如果响应的状态码不是 2xx，则抛出错误
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              console.log('Upload successful:', data);
              resolve(); // 在上传成功后调用 resolve 函数，表示 Promise 已成功完成
            })
            .catch(error => {
              console.error('Upload failed:', error);
              reject(error); // 在捕获到错误时调用 reject，传递错误信息，表示 Promise 被拒绝
            });
      });
    },

    saveEar() {
      //   TODO

    },
    savePulse() {
      //   TODO
    },


    // 旧的goto chen
    // async goToChen() {
    //   return fetch(BaseUrl+'9003'+'/v1/chat/completions', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(this.requestBody),
    //
    //   }).then((response) => response.json())
    //       .then((data) => {
    //
    //         Swal.fire({
    //           title: '诊断 结果',
    //           text: data.choices[0].message.content,
    //           imageUrl: 'https://itcm-wyu.oss-cn-guangzhou.aliyuncs.com/example/logo/imedLOGO.png',
    //           imageWidth: 200,
    //           imageHeight: 200,
    //           showCancelButton: true,
    //           allowOutsideClick: false,
    //           confirmButtonText: '保存为PDF',
    //           cancelButtonText: '取消',
    //         }).then((result) => {
    //           if (result.isConfirmed) {
    //             this.SavePDF(data.choices[0].message.content);
    //             // this.saveAsPDF(data.choices[0].message.content);
    //           }
    //         });
    //       })
    //       .catch((error) => {
    //         // 处理错误
    //         console.error('Error:', error);
    //       });
    // }

    // 新的goto chen
    async goToChen() {
      try {
        const response = await fetch(BaseUrl + '9003' + '/v1/chat/completions', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.requestBody),
        });

        const data = await response.json();
        const diagnosticContent = data.choices[0].message.content;
        console.log(typeof diagnosticContent);
        const personalInformation = '病号：' + this.user.medicalRecordNumber + '&nbsp; &nbsp; &nbsp;身高：' + this.user.height + 'cm &nbsp; &nbsp; &nbsp; 体重：' + this.user.weight + 'kg &nbsp; &nbsp; &nbsp; 年龄：' + this.user.age + '岁 &nbsp; &nbsp; &nbsp; 性别：' + this.user.gender + '<br><br>' + '既往病史：' + this.pastMedicalHistory.join(",") + '<br><br>' + '最近症状：' + this.user.recentSymptoms + '<br><br>';
        const pdfText = diagnosticContent
        const processedText = diagnosticContent.replace(/\n/gm, "<br>");
        const finalText = personalInformation + processedText;
        console.log(diagnosticContent);

        Swal.fire({
          title: '诊断 结果',
          html: `<div class="justified-text">${finalText}</div>`,
          imageUrl: 'https://itcm-wyu.oss-cn-guangzhou.aliyuncs.com/example/logo/imedLOGO.png',
          imageWidth: 200,
          imageHeight: 200,
          showCancelButton: true,
          allowOutsideClick: false,
          confirmButtonText: '保存为PDF',
          cancelButtonText: '取消',
          customClass: {
            popup: 'custom-swal-popup-class',
            content: 'custom-swal-content-class',
            confirmButton: 'custom-swal-confirmButton-class',
            cancelButton: 'custom-swal-cancelButton-class',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.SavePDF(pdfText);
          }
        });
      } catch (error) {
        console.error('Error:', error);
      }
    }
    ,


    async SavePDF(text) {
      try {
        // 发送请求到 FastAPI 接口
        const response = await fetch(BaseUrl+'9000' +'/CreatPDF/download_pdf', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            medicalRecordNumber: this.user.medicalRecordNumber,
            height: this.user.height,
            weight: this.user.weight,
            age: this.user.age,
            gender: this.user.gender,
            pastMedicalHistory: this.pastMedicalHistory.join(","),
            recentSymptoms: this.user.recentSymptoms,
            text: text,
          }),
        });
        // 检查响应状态
        if (response.ok) {
          // 如果响应状态码为 200-299
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '报告.pdf'); // 设置下载的文件名
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url); // 释放内存中的对象URL
        } else {
          console.error('Network response was not ok.');
        }
      } catch (error) {
        console.error('There has been a problem with your fetch operation:', error);
      }
    },
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
.swal2-success .swal2-success-ring{
  /* 你可以在这里设置图标的背景图片、大小等 */
  background-image: url('../src/assets/wyu.png');
  //background-image: image('../src/assets/logo.png');
  background-size: contain;
  width: 100px; /* 根据你的图标大小调整 */
  height: 100px; /* 根据你的图标大小调整 */
}

.custom-swal-popup-class {
  width: 70%; /* 设置弹窗宽度 */
  max-width: 700px; /* 可选：设置最大宽度 */
}

.custom-swal-content-class {
  /* 可选：调整弹窗内容区域的样式 */
}

.custom-swal-confirmButton-class {
  /* 可选：调整确认按钮的样式 */
}

.custom-swal-cancelButton-class {
  /* 可选：调整取消按钮的样式 */
}

.justified-text {

  text-align: justify; /* 设置文本两端对齐 */

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); /* 半透明背景 */
  z-index: 1000; /* 确保在页面最上层 */
  display: flex;
  justify-content: center; /* 水平方向居中 */
  align-items: center; /* 垂直方向居中 */
}

.spinner-wrapper {
  display: flex;
  flex-direction: column; /* 垂直方向排列 */
  align-items: center; /* 水平方向居中 */
}
.loading-text {
  margin-top: 1rem;
  color: white;
  font-size: 2.2rem;
  font-weight: bold; /* 将字体设置为粗体 */
  text-align: center; /* 使文本居中对齐 */
}
</style>
