<template>
    <div class="home2">
    <div class="container-face flex-column">
      <h2>舌诊</h2>
      <div class="camera-container">
        <div class="video-container">
          <img class="tongue-image" src="../assets/舌头.png" alt="" v-show="showTonguePrompt">
          <video ref="video" id="video" autoplay class="video"></video>
        </div>
      </div>
      <button id="snap"  v-on:click="capture()">拍照</button>
      <canvas ref="canvas" id="canvas" width="640" height="480" style="display: none" ></canvas>
    </div>

      <!-- 拍照结果和上传区域，放在右侧 -->
      <div class="side-container">
        <div class="captured-photos">
          <div v-if="user_upload_photo" class="uploaded-photo-container">
            <img class="user_uploaded-photo" :src="user_upload_photo"  alt=""/>
            <el-button v-show="user_upload_photo" @click="removeUserUploadPhoto" type="danger">删除</el-button>
          </div>
          <div class="custom-file-upload" v-if="!user_upload_photo">
            自己上传照片
            <input  placeholder="点击上传文件" type="file" @change="userUploadPhoto"  accept="image/*">
          </div>
        </div>
        <div>
          <el-button type="success"  @click="submitPhoto" v-if="user_upload_photo">保存</el-button>
        </div>
      </div>
     </div>
</template>

<script>
// @ is an alias to /src
export default {
  data() {
    return {
      // 初始化所需的数据...
      video: {}, // 用于存储视频
      canvas: {}, // 用于存储拍照后的canvas
      user_upload_photo:null,
      showTonguePrompt:true, //显示舌头提示框
      filtered_results: [],
    };
  },

  mounted() {
    this.video = this.$refs.video;
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true }).then(stream => {
        this.video.srcObject = stream;
        this.video.play();
      });
    }
  },
  methods: {
    capture() {
      this.canvas = this.$refs.canvas;
      var context = this.canvas.getContext("2d").drawImage(this.video, 0, 0, 640, 480);
      this.user_upload_photo = this.canvas.toDataURL("image/png")
    },


    userUploadPhoto(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        //   this.photos.push(e.target.result);
        this.user_upload_photo = e.target.result;
      };

      reader.readAsDataURL(file);
      event.target.value = '';
    },

    submitPhoto() {
      this.$emit('submit-tongue-view', this.user_upload_photo);
      this.$notify({
        title: '成功',
        message: '舌诊内容已保存，请完成闻诊',
        type: 'success'
      });
      return true;
    },
    removeUserUploadPhoto() {
      this.user_upload_photo = null;
      this.filtered_results = [];

    }
  }
}
</script>

<style>
.home2 {
  display: flex;
  flex-direction: column;
  //align-items: flex-start;
  align-items: center;
  justify-content: center; /* 水平居中container-face */
  height: 500px;
  padding: 20px;
  gap: 20px; /* 添加组件之间的间距 */
}
.container-tongue{
  position: absolute;
  flex: 1; /* 根据需要调整占位符大小 */
  max-width: 640px; /* 视频画布的宽度 */
  margin-right: 20px; /* 与右侧内容的间距 */
}
.flex-column {
  display: flex;
  flex-direction: column; /* 子元素垂直排列 */
}
.camera-container {
  position: relative;
  width: 100%; /* 或者与video同宽 */
  margin-bottom: 20px;
}
.video-container {
  width: 100%;
  border-radius: 10px; /* 添加圆角 */
  overflow: hidden; /* 隐藏超出部分 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 添加阴影 */
}
.side-container {
  position: relative;
  left: 30%;
  padding: 20px; /* 内边距 */
}
.video {
  width: 440px;
  height: auto;
  display: block;
  border: none;
}

#snap {
  height: auto;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
#snap:hover {
  background-color: #0056b3;
}
.face-image {
  width: 240px;
  height: 240px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999; /* 使人脸图片在摄像头之上 */
}
.tongue-image {
  width: 180px;
  height: 180px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 999; /* 使人脸图片在摄像头之上 */
}
.captured-photos {
  display: flex;
  flex-direction: column;
//flex-direction: row; /* 水平排列上传的照片 */
  align-items: center; /* 垂直居中对齐 */
  margin-top: 20px;
}

.uploaded-photo-container {
  position: relative;
  display: flex;
  flex-direction: column;
}
.user_uploaded-photo {
  max-width: 200px; /* 设置上传照片的宽度 */
  max-height: 200px; /* 设置上传照片的高度 */
  border: 1px solid #ccc;
  border-radius: 10px; /* 添加圆角 */
  object-fit: cover; /* 保持图片比例 */
}
button {
  padding: 8px 15px;
  font-size: 14px;
  color: #fff;
  background-color: #28a745;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
input[type="file"] {
//opacity: 0;    /* 使文件输入框完全透明，但仍然可以交互 */
  width: 140px;
  height: 60px; /* 设置一个足够的高度 */
  position: absolute; /* 定位到自定义上传按钮的位置 */
  cursor: pointer; /* 改变鼠标指针样式，表示可点击 */
}
.results{
  border: 1px solid #ccc;
  padding: 10px;

}
</style>
